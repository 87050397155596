import React, { useEffect } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { useAuth } from "../context/auth-context"
import { useUser } from "../context/user-context"
import { isBrowser } from "../utils/browser"
import { useMutation } from "@apollo/client"
import { MUTATION_TRACK_EMAIL_CLICKS } from "../graphql/mutations/email_analitics"

function PrivateRoute({ component: Component, location, ...rest }) {
  const { isLoggedIn } = useAuth()
  const user = useUser()
  const queryParams = new URLSearchParams(location.search)
  const upgradeModalOpen = queryParams.get("open-upgrade-modal")
  const redirect = queryParams.get("redirect")
  const taskId = queryParams.get("taskId")
  const emailEvenId = queryParams.get("eventId")

  const intendedURL = isBrowser() ? localStorage.getItem("intendedURL") : null
  const excludeFromIntended = ["login", "register", "register-employee"]
  const redirectURL = isBrowser() ? localStorage.getItem("redirectURL") : null
  const redirectTaskId = isBrowser()
    ? localStorage.getItem("redirectTaskId")
    : null

  let userAfterLossPlans = 0
  user?.plans?.map((p) => p?.scenario !== "preparing" && userAfterLossPlans++)

  let userOldPrePlans = 0
  user?.plans?.map(
    (p) => p?.intake_response?.scenario === "preparing" && userOldPrePlans++
  )

  const [trackEmailClicks] = useMutation(MUTATION_TRACK_EMAIL_CLICKS)

  useEffect(() => {
    if (emailEvenId) {
      trackEmailClicks({ variables: { eventId: emailEvenId } })
    }
  }, [])

  if (upgradeModalOpen && !isLoggedIn() && !user) {
    window?.sessionStorage?.setItem("open-upgrade-modal", true)
  }
  
  if (isBrowser() && window.heap && isLoggedIn() && user) {
    const heapAPI = window.heap
    heapAPI.identify(user.id)
    heapAPI.addUserProperties({
      first_name: user.first_name,
      email: user.email,
      is_email_verified: user.is_email_verified,
      email_verified_at: user.email_verified_at,
      membership_plan: user.membership_plan,
      last_login_at: user.last_login_at,
      user_preplans: user.prePlanningPlans?.length,
      user_after_loss_plans: userAfterLossPlans,
      user_old_preplans: userOldPrePlans,
      user_type: user.user_type,
      account_source: user.account_source,
      user_created_at: user.created_at,
      scenario: user.signup_scenario,
      nps_score: user.nps?.score,
      nps_detracting_answer: user.nps?.detracting_score_answer,
    })
  }

  if (isBrowser() && redirect) {
    localStorage.setItem("redirectURL", redirect)
    localStorage.setItem("redirectTaskId", taskId)
  }

  if (isLoggedIn() && user && user?.requires_email_verification && !user?.is_email_verified) {
    navigate("/email-verify")
    return () => null
  }

  if (isLoggedIn() && user && user?.has_mfa && !user?.is_mfa_verified) {
    navigate("/mfa")
    return () => null
  }

  if (
    isBrowser() &&
    !isLoggedIn() &&
    !excludeFromIntended.includes(location.pathname)
  ) {
    localStorage.setItem("intendedURL", location.pathname + location.search)
    navigate("/login")
    return () => null
  }

  if (isBrowser() && redirectURL) {
    localStorage?.removeItem("redirectURL")
    localStorage?.removeItem("redirectTaskId")
    navigate(`/${redirectURL}?taskId=${redirectTaskId}`)
    return () => null
  }

  if (isBrowser() && redirect) {
    localStorage?.removeItem("redirectURL")
    localStorage?.removeItem("redirectTaskId")
    navigate(`/${redirect}?taskId=${taskId}`)
    return () => null
  }

  if (user && intendedURL) {
    localStorage?.removeItem("intendedURL")
    navigate(intendedURL)
    return () => null
  }

  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
}

export default PrivateRoute
