import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { useMutation } from "@apollo/client"
import { VERIFY_TEAM_INVITES } from "../graphql/mutations/team"
import LoadingAnimation from "../components/loading-animation"
import { useAuth } from "../context/auth-context"
import { useLocation } from "@reach/router"

const VerifyToken = ({ token, artifact }) => {
  const { refetchUser } = useAuth()
  const location = useLocation()
  const query = new URLSearchParams(location?.search)
  const [verifyTeamInvites, { loading: verifyingInvite }] =
    useMutation(VERIFY_TEAM_INVITES)
  const [refetchingUser, setRefetchingUser] = useState(false)

  useEffect(() => {
    if (artifact === "team") {
      verifyTeamInvites({
        variables: { input: { token } },
      })
        .then((result) => {
          if (result?.data?.verifyTeamInvites?.status === 200) {
            sessionStorage.setItem("collaborator", true)
          }
          sessionStorage.setItem("first_login", true)
          sessionStorage.setItem("scenario",  query?.get("scenario"))
          setRefetchingUser(true)
          refetchUser()
            .then(() => {
              if (query?.get("type")) {
                // collaborator
                navigate(
                  `/app/${
                    query?.get("type") === "PrePlanningPlan"
                      ? "pre-plan"
                      : "plan"
                  }/${query?.get("plan")}`
                )
              } else {
                // beneficiary
                navigate("/app")
              }
              setRefetchingUser(false)
            })
            .catch(() => {
              navigate("/app")
              setRefetchingUser(false)
            })
        })
        .catch(() => {
          navigate("/app")
        })
    }
  }, [])

  return verifyingInvite || refetchingUser ? <LoadingAnimation /> : null
}

VerifyToken.propTypes = {
  token: PropTypes.string,
  artifact: PropTypes.string,
}

export default VerifyToken
