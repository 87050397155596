import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../components/private-route"
import PlanPage from "./client-only/plan"
import PrePlanPage from "./client-only/pre-plan"
import AccountSettingsPage from "./client-only/account-settings"
import TopicPage from "./client-only/topic"
import PrePlanTopicPage from "./client-only/pre-planning-topic"
import DashboardPage from "../pages/client-only/dashboard"
import Articles from "./articles"
import ArticleTemplate from "./article"
import NotFoundPage from "../pages/404"
import VerifyToken from "../components/verify-token"
import Funeralocity from "./funeralocity"

const App = () => (
  <Router basepath="/app">
    <PrivateRoute path="/" component={DashboardPage} />
    <PrivateRoute path="/dashboard" component={DashboardPage} />
    <PrivateRoute path="/plan/:planId" component={PlanPage} />
    <PrivateRoute path="/pre-plan/:planId" component={PrePlanPage} />
    <PrivateRoute path="/plan/:planId/topic/:topicSlug" component={TopicPage} />
    <PrivateRoute
      path="/pre-plan/:planId/topic/:topicId"
      component={PrePlanTopicPage}
    />
    <PrivateRoute
      path="/pre-plan/:planId/topic/:topicId/task/:taskId"
      component={PrePlanTopicPage}
    />
    <PrivateRoute path="/account/settings" component={AccountSettingsPage} />
    <PrivateRoute path="/articles" component={Articles} />
    <PrivateRoute path="/articles/:articleSlug" component={ArticleTemplate} />
    <PrivateRoute path="/funeralocity" component={Funeralocity} />
    <VerifyToken path="/verify-token/:token/:artifact" />
    <NotFoundPage default />
  </Router>
)

export default App
